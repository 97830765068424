.welcome-title {
    color: #00326d;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 50px;
    line-height: 32px;
    font-weight: bold;
    margin-left: 3rem;
    margin-top: 3rem;
}

.welcome-subtitle {
    color: #00326d;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 25px;
    line-height: 32px;
    font-weight: bold;
    margin-left: 3.1rem;
}

body {
    background-color: #FAF9F6;
}

@media screen and (max-width: 500px) {}