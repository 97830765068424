.footer-background {
    margin-top: 5rem;
    width: 100%;
    background: #08131D;
}

.footer-container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.contact-footer {
    width: 25%;
}

.logo-footer {
    width: 25%;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.socials {
    display: block;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    color: white;
}

.instaLogo:hover {
    background: linear-gradient( 45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
}

.facebookLogo:hover {
    color: #4267B2
}

.linkedIn:hover {
    color: #0072b1;
}

.youtube:hover {
    color: #FF0000
}

.socials:hover {
    cursor: pointer;
}

div.container-footer.w-container {
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    max-width: 940px;
    padding-bottom: 1rem;
    padding-top: 1rem;
}

h3.footer-titles {
    box-sizing: border-box;
    color: #FFFFFF;
    display: inline-block;
    font-family: Lato, sans-serif;
    font-size: 20px;
    font-weight: 900;
    line-height: 1.1em;
    margin: 0;
}

.link-footer {
    background-color: transparent;
    box-sizing: border-box;
    color: #FFFFFF;
    font-family: Lato, sans-serif;
    font-size: 17px;
    font-weight: 400;
    line-height: 1.2em;
    text-decoration: none;
}

img {
    border-width: 0;
    box-sizing: border-box;
    display: inline-block;
    max-width: 100%;
    vertical-align: middle;
}

p.footer-description {
    box-sizing: border-box;
    color: rgba(255, 255, 255, 0.8);
    display: block;
    font-family: Lato, sans-serif;
    font-size: 17px;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 1.5em;
    margin-bottom: 16px;
    margin-top: 15px;
}

@media (max-width: 500px) {
    .contact-footer {
        margin-right: 5rem;
    }
}